import React from 'react'
import { Flipper, Flipped } from 'react-flip-toolkit'
import { THEME } from 'utils'
import MediaQuery from 'components/MediaQuery'
import { Skeleton } from 'components/Skeleton'
import SimpleStoryCard from 'components/SimpleStoryCard'
import { feedEvents } from 'lib/ga'

const TrendingStories = ({ trendingStories }) => {
  if (!trendingStories.length === 0) {
    return <ComponentSkeleton />
  }
  const joinedIds = trendingStories.map((story) => story.id).join('|')
  return (
    <>
      <div className='trendingStories__title'>Top Stories</div>
      <div className='trendingStories'>
        <MediaQuery minWidth={THEME.BREAKPOINTS.SM}>
          <Flipper flipKey={joinedIds}>
            {trendingStories.map((story, index) => {
              let compact = index !== 0
              let imageAspectRatio = compact ? '1:1' : '4:3'
              return (
                <Flipped key={story.id} flipId={story.id}>
                  <div
                    className='trendingStories__item'
                    key={story.id}
                    onClick={() => {
                      feedEvents.trending(story.url, index + 1)
                    }}
                  >
                    <SimpleStoryCard
                      story={story}
                      options={{ trendingRank: index + 1, showIndicator: false, compact, imageAspectRatio }}
                    />
                  </div>
                </Flipped>
              )
            })}
          </Flipper>
        </MediaQuery>
        <MediaQuery maxWidth={THEME.BREAKPOINTS.SM - 1}>
          {trendingStories.map((story, index) => (
            <div
              className='trendingStories__item'
              key={story.id}
              onClick={() => {
                feedEvents.trending(story.url, index + 1)
              }}
            >
              <SimpleStoryCard
                story={story}
                options={{
                  thumbnailProps: {
                    sizes: '(max-width: 768px) 33vw, 100vw'
                  },
                  showAuthor: false,
                  imageAspectRatio: '1:1',
                  showIndicator: false,
                  showImageMobile: true
                }}
              />
            </div>
          ))}
        </MediaQuery>
      </div>
      <style jsx>{`
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .trendingStories {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding: 12px 10px;
            margin-left: -10px;
            margin-right: -10px;
          }
        }
        .trendingStories__title {
          color: #999999;
          font-size: 0.625rem;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.05rem;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .trendingStories__title {
            font-size: 0.75rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG + 1}px) {
          .trendingStories__title {
            display: none;
          }
        }
        .trendingStories__item {
          background-color: #fff;
          padding-bottom: 2px;
          margin-bottom: 8px;
        }
        .trendingStories__item:last-of-type {
          border-bottom: none;
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .trendingStories__item {
            border-bottom: none;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .trendingStories__item {
            border-bottom: 1px solid #f1f1f1;
          }
        }
      `}</style>
    </>
  )
}

function ComponentSkeleton() {
  return (
    <div className='mb-16 last:mb-0 gap-4'>
      <Skeleton className='!h-[188px] w-full mb-4' />
      <div className='flex gap-3 mb-3'>
        <Skeleton className='!h-[72px]' />

        <div className='w-full'>
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-[60%]' />
        </div>
      </div>
      <Skeleton className='w-full h-[1px] mb-3' />
      <div className='flex gap-3 mb-3'>
        <Skeleton className='!h-[72px]' />

        <div className='w-full'>
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-[60%]' />
        </div>
      </div>
      <Skeleton className='w-full h-[1px] mb-3' />
      <div className='flex gap-3 mb-3'>
        <Skeleton className='!h-[72px]' />

        <div className='w-full'>
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-[60%]' />
        </div>
      </div>
      <Skeleton className='w-full h-[1px] mb-3' />
      <div className='flex gap-3 mb-3'>
        <Skeleton className='!h-[72px]' />

        <div className='w-full'>
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-full' />
          <Skeleton className='h-3 mb-2 w-[60%]' />
        </div>
      </div>
      <Skeleton className='w-full h-[1px] mb-3' />
    </div>
  )
}

export default TrendingStories
