import FeedNavigation from 'components/FeedNavigation'
import LiveVideos from 'components/LiveVideos'
import Sidebar from 'components/Sidebar'
import StoryList from 'components/StoryList'
import TopStories from 'components/TopStories'
import { CONTENT_LAYOUT_SECTIONS } from 'constants/contentLayouts'
import { useStore } from 'hooks'
import { usePinnedItems } from 'hooks/usePinnedItems'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { StoriesStore } from 'stores/stories'
import { THEME } from 'utils'

const FEED_NAV_OPTIONS = [
  {
    label: 'Latest',
    href: '/',
    current: true
  },
  {
    label: 'My Feed',
    href: '/favorites',
    current: false
  }
]

const Home = observer(({ items, next, hasMore, page, adSettings }) => {
  const storiesStore = useStore(StoriesStore)

  const stories = usePinnedItems({
    key: CONTENT_LAYOUT_SECTIONS.FEED_SECTION,
    items: items,
    contentType: 'story',
    positions: {
      slot1: 0,
      slot2: 1,
      slot3: 2,
      slot4: 3,
      slot5: 4,
      slot6: 5,
      slot7: 6,
      slot8: 7,
      slot9: 8,
      slot10: 9
    }
  })

  return (
    <>
      <div className='container'>
        {storiesStore.liveItems.length ? (
          <section className='liveVideos__wrap'>
            <LiveVideos items={storiesStore.liveItems} />
          </section>
        ) : null}
        <div className='row'>
          <main className='col col--main'>
            <TopStories />
            <div className='stories-container'>
              <FeedNavigation navOptions={FEED_NAV_OPTIONS} />
              <div className='latestStories'>
                <StoryList items={stories} next={next} hasMore={hasMore} homepage={true} adSettings={adSettings} />
              </div>
            </div>
          </main>
          <div className='col col--sidebar'>
            <Sidebar adSettings={adSettings} page={page} home={true} />
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
          padding-left: 0;
          padding-right: 0;
        }
        .liveVideos__wrap {
          margin-bottom: 15px;
        }
        @media (min-width: 480px) {
          .container {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .container > .row {
          display: grid;
          grid-template-columns: 1fr 300px;
          grid-column-gap: 30px;
          margin-left: 0;
          margin-right: 0;
        }
        .container > .row > .col {
          padding: 0;
        }
        .btn {
          width: auto;
        }
        .stories-container {
          background-color: white;
          padding: 0;
          box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
        }
        @media (max-width: 480px) {
          .col--main {
            padding-left: 0;
            padding-right: 0;
          }
        }
        @media (min-width: 480px) {
          .container {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .container > .row {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </>
  )
})

export default Home
