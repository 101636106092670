import { useMemo } from 'react'
import { useStore } from 'hooks'
import ContentLayoutsStore from 'stores/content-layouts'

export function usePinnedItems({ key, items, contentType, positions }) {
  const contentLayoutsStore = useStore(ContentLayoutsStore)

  return useMemo(() => {
    // Find the correct slots based on section key
    const slots = contentLayoutsStore.items[0]?.sections?.find((section) => section.key === key).slots ?? []

    // Clone the passed items
    const tempItems = [...items]

    // Store the ids of added items
    const newItems = new Set()

    // Loop through story slots and splice in data
    for (const slot of slots.filter((slot) => slot.content_type === contentType)) {
      if (positions[slot.position] >= 0) {
        tempItems.splice(positions[slot.position], 0, { pinned: true, ...slot.data })
        newItems.add(slot.data.id)
      }
    }

    return tempItems.filter((item) => item.pinned || newItems.has(item.id) === false)
  }, [items.map((item) => item.id).join('-')])
}
