import React from 'react'
import { observer } from 'mobx-react-lite'
import FeaturedStories from 'components/FeaturedStories'
import TrendingStories from 'components/TrendingStories'
import StoriesStore from 'stores/stories'
import { useInterval, useStore } from 'hooks'
import { usePinnedItems } from 'hooks/usePinnedItems'
import { CONTENT_LAYOUT_SECTIONS } from 'constants/contentLayouts'
import { THEME } from 'utils'

const TopStories = observer(() => {
  const storiesStore = useStore(StoriesStore)
  const trendingStories = storiesStore.trendingItems.slice(0, 5)
  const featuredStories = usePinnedItems({
    key: CONTENT_LAYOUT_SECTIONS.FEATURED_SECTION,
    items: storiesStore.featuredItems,
    contentType: 'story',
    positions: { slot1: 0, slot2: 1, slot3: 2 }
  }).slice(0, 3)

  useInterval(() => {
    storiesStore.findTrending({ exclude_featured: true, limit: 5 })
  }, 30 * 1000)

  return (
    <section className='topStories'>
      <div className='featuredStories-container'>
        <FeaturedStories featured={featuredStories} />
      </div>
      <div className='trendingStories-container'>
        <TrendingStories trendingStories={trendingStories} />
      </div>

      <style jsx>{`
        .topStories {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-auto-flow: column;
          grid-column-gap: 20px;
          padding: 18px;
          background-color: white;
          margin-bottom: 24px;
          box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
        }
        @media (max-width: ${THEME.BREAKPOINTS.XS}px) {
          .topStories {
            padding: 10px;
          }
        }
        .featuredStories-container {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .featuredStories-container {
            border-bottom: 1px solid #f1f1f1;
          }
        }
        .trendingStories-container {
          grid-column-start: 3;
          grid-column-end: 4;
        }
        @media (max-width: ${THEME.BREAKPOINTS.LG}px) {
          .featuredStories-container {
            grid-column-start: 1;
            grid-column-end: 4;
          }
          .trendingStories-container {
            grid-column-start: 1;
            grid-column-end: 4;
            padding-top: 14px;
          }
        }
        .featuredStories > .row {
          flex-direction: column;
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 15px;
          margin: 0;
        }
        .featuredStories .col {
          padding: 12px;
        }
        @media (max-width: 480px) {
          .featuredStories .row {
            margin: 0;
          }
        }
        @media (min-width: 481px) {
          .featuredStories .col {
            padding: 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .featuredStories > .row {
            grid-template-columns: 1fr 1fr;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .featuredStories > .row {
            grid-template-columns: 1fr;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .featuredStories > .row {
            grid-template-columns: 1fr 1fr;
          }
        }
      `}</style>
    </section>
  )
})

export default TopStories
