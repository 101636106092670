import classNames from 'classnames'
import { useMemo } from 'react'

export function Skeleton({
  shimmer = true,
  ...props
}: {
  className?: string
  round?: boolean
  wide?: boolean
  extrawide?: boolean
  fitContainer?: boolean
  shimmer?: boolean
}) {
  const compositeClassName = useMemo(
    () =>
      classNames(
        {
          'h-4': !props.round && !props.fitContainer,
          'w-12 h-12 ': props.round,
          'w-12': !props.wide && !props.extrawide && !props.fitContainer,
          'w-24': props.wide,
          'w-36': props.extrawide,
          'skeleton-circle': props.round,
          skeleton: !props.round,
          shimmer
        },
        props.className
      ),
    [props.className, props.extrawide, props.fitContainer, props.round, props.wide, shimmer]
  )
  return (
    <>
      <div className={compositeClassName}></div>
    </>
  )
}
