import React from 'react'

import SimpleStoryCard from 'components/SimpleStoryCard'

import { feedEvents } from 'lib/ga'
import { THEME } from 'utils'
import { Skeleton } from 'components/Skeleton'

const FeaturedStories = ({ featured, displayOptions }) => {
  if (!featured?.length) return <ComponentSkeleton />
  const [primaryFeatured, ...otherFeatured] = featured

  return (
    <>
      <div className='featuredStories'>
        <div
          className='featuredStory featuredStory--primary'
          onClick={() => {
            feedEvents.featured(primaryFeatured.url, 1, primaryFeatured.pinned)
          }}
        >
          <SimpleStoryCard
            story={primaryFeatured}
            options={
              displayOptions?.primary ?? {
                large: true,
                featuredImage: true,
                thumbnailProps: { sizes: '(max-width: 768px) 100vw, (max-width: 1024px) 75vw, 600px' }
              }
            }
          />
        </div>
        {otherFeatured.slice(0, 2).map((story, index) => {
          return (
            <div
              className='featuredStory'
              key={story.id}
              onClick={() => {
                feedEvents.featured(story.url, index + 1, story.pinned)
              }}
            >
              <SimpleStoryCard
                story={story}
                options={
                  displayOptions?.secondary ?? {
                    thumbnailProps: {
                      sizes: '(max-width: 768px) 50vw, 300px'
                    },
                    imageAspectRatio: '4:3'
                  }
                }
              />
            </div>
          )
        })}
      </div>
      <style jsx>{`
        .featuredStories {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 16px;
          margin-bottom: 20px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG + 1}px) {
          .featuredStories {
            margin-bottom: 0;
          }
        }
        .featuredStory--primary {
          grid-column-start: 1;
          grid-column-end: 3;
          margin-bottom: 28px;
        }
      `}</style>
    </>
  )
}

function ComponentSkeleton() {
  return (
    <div className='mb-16 last:mb-0 gap-4'>
      <Skeleton className='!h-[300px] w-full mb-4' />
      <Skeleton className='w-[55%] mb-4 h-5' />
      <div className='flex w-full gap-4'>
        <div className='w-full'>
          <Skeleton className='w-full h-[190px] mb-4' />
          <Skeleton className='w-full h-3' />
        </div>
        <div className='w-full'>
          <Skeleton className='w-full h-[190px] mb-4' />
          <Skeleton className='w-full h-3 mb-2' />
          <Skeleton className='w-full h-3' />
        </div>
      </div>
    </div>
  )
}

export default FeaturedStories
