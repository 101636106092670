import Script from 'next/script'
import { withLogger } from 'lib/logger'
import ExternalScriptLoader, { providers } from './external-script-loader'
import { isAdProviderEnabled } from 'utils'

const logger = withLogger('Nativo')

const NATIVO_SRC = 'https://s.ntv.io/serve/load.js'

class Nativo extends ExternalScriptLoader {
  scriptUrl = 'https://s.ntv.io/serve/load.js'
  readyStates = ['complete', 'interactive']
  providerName = providers.nativo

  init() {
    window._prx = window._prx ?? [['cfg.SetNoAutoStart']]
  }

  async start() {
    if (!isAdProviderEnabled('nativo')) return
    try {
      await this.loadScript()
      logger.log('start()')
      window.PostRelease.Start()
    } catch (err) {
      logger.error('start():', err)
    }
  }

  async subscribe(event, callback) {
    if (!isAdProviderEnabled('nativo')) return
    try {
      await this.loadScript()
      logger.log('subscribe():', event)
      window.ntv.Events.PubSub.subscribe(event, callback)
    } catch (err) {
      logger.error('subscribe():', err)
    }
  }
}

export default new Nativo()

export function NativoScript({ onReady }) {
  function init() {
    window._prx = window._prx ?? [['cfg.SetNoAutoStart']]
  }

  return (
    <Script
      src={NATIVO_SRC}
      strategy='afterInteractive'
      onLoad={() => {
        init()
        onReady?.()
      }}
    />
  )
}

export async function start() {
  if (!isAdProviderEnabled('nativo')) return
  try {
    logger.log('start()')
    window.PostRelease.Start()
  } catch (err) {
    logger.error('start():', err)
  }
}

export async function subscribe(event, callback) {
  console.log({ event, callback, enabled: isAdProviderEnabled('nativo') })
  if (!isAdProviderEnabled('nativo')) return
  try {
    logger.log('subscribe():', event)
    console.log('subscribe event', window.ntv.Events.PubSub.subscribe)
    window.ntv.Events.PubSub.subscribe(event, callback)
  } catch (err) {
    logger.error('subscribe():', err)
  }
}
