import type { Story, StoryVideoSource, Brand, Author } from 'api/types'
import Script from 'next/script'
import { truncateHtmlString, stripHtml } from 'utils'

export const brandDescription =
  'Barstool Sports is a sports & pop culture blog covering the latest news and viral highlights of each and everyday with blogs, videos and podcasts.  By the common man, for the common man.'

export function formatStoryStructuredDataMarkup(story: Story) {
  const { title, thumbnail } = story
  const images = [thumbnail.desktop, thumbnail.featured]

  const schema = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: title,
    image: images,
    thumbnailUrl: images?.[0],
    url: story?.url,
    keywords: story?.verity?.keywords,
    datePublished: new Date(story.date).toISOString(),
    dateModified: new Date(story.updated_at).toISOString(),
    publisher: SEO_PUBLISHER_DEFAULT,
    interactionStatistic: {
      '@type': 'InteractionCounter',
      interactionType: 'http://schema.org/CommentAction',
      userInteractionCount: story?.comment_count
    },
    author: {
      '@type': 'Person',
      name: story?.author?.name,
      url: story?.author?.author_url,
      ...(story?.brand_name ? { brand: { '@type': 'Brand', name: story?.brand_name } } : {})
    }
  })

  return schema ? (
    <Script type='application/ld+json' id='blog-seo'>
      {schema}
    </Script>
  ) : null
}

export function formatVideoStructuredDataMarkup(videoSource: StoryVideoSource, story: Story) {
  const { name, duration, thumbnail_sources, published_at, sources } = videoSource

  const truncatedStoryHtml = stripHtml(truncateHtmlString(story?.post_type_meta?.standard_post?.raw_content ?? '', 200))
  const images = thumbnail_sources?.map(({ src }: { src: string }) => src)
  const src = sources?.[0]?.src
  const chapters = videoSource?.cue_points?.filter((cp) => cp.type === 'CHAPTER')
  const isLiveStream = story?.post_type_meta?.live !== undefined

  function getPublication() {
    return {
      '@type': 'BroadcastEvent',
      name: story?.brand_name,
      isLiveBroadcast: true,
      startDate: story?.post_type_meta?.live?.datetime && new Date(story.post_type_meta.live.datetime).toISOString(),
      endDate: story?.post_type_meta?.live?.end_date && new Date(story.post_type_meta.live.end_date).toISOString()
    }
  }

  function getParts() {
    return chapters?.map(({ time, name }) => ({
      '@type': 'Clip',
      name: name,
      startOffset: time
    }))
  }

  const publication = getPublication()

  const schema = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: name,
    uploadDate: new Date(published_at).toISOString(),
    thumbnailUrl: images,
    contentUrl: src,
    text: name,
    embedUrl: src,
    description: truncatedStoryHtml,
    duration: `PT${duration}S`,
    publisher: SEO_PUBLISHER_DEFAULT,
    author: {
      '@type': 'Person',
      name: story?.author?.name,
      url: story?.author?.author_url,
      ...(story?.brand_name ? { brand: { '@type': 'Brand', name: story?.brand_name } } : {})
    },
    interactionStatistic: {
      '@type': 'InteractionCounter',
      interactionType: 'http://schema.org/CommentAction',
      userInteractionCount: story?.comment_count
    },
    ...(isLiveStream && { publication }),
    ...(chapters?.length && { hasPart: getParts() })
  })

  return schema ? (
    <Script id='video-schema' type='application/ld+json'>
      {schema}
    </Script>
  ) : null
}

export function formatPodcastStructuredDataMarkup(story: Story) {
  if (!story.post_type_meta?.podcast) return null
  const { title, duration, created_at, iframe_src, description, podcast, show, brand, authors } =
    story.post_type_meta.podcast

  const brandPageUrl = `${process.env.SITE_URL}/shows/${brand.id}/${show.slug}`
  const podcastAuthors = authors.map(({ name, author_url }) => ({
    '@type': 'Person',
    name: name,
    url: author_url,
    brand: {
      '@type': 'Brand',
      name: brand.name,
      url: brandPageUrl,
      description: show.description,
      logo: show.image
    }
  }))

  const schema = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'PodcastEpisode',
    name: title,
    url: iframe_src,
    description: description,
    timeRequired: 'PT' + duration + 'S',
    datePublished: new Date(created_at).toISOString(),
    publisher: SEO_PUBLISHER_DEFAULT,
    partOfSeries: {
      '@type': 'PodcastSeries',
      name: podcast.title,
      url: brandPageUrl,
      image: show.image,
      description: show.description
    },
    audio: {
      '@type': 'AudioObject',
      contentUrl: iframe_src,
      thumbnailUrl: show.image
    },
    author: podcastAuthors,
    interactionStatistic: {
      '@type': 'InteractionCounter',
      interactionType: 'http://schema.org/CommentAction',
      userInteractionCount: story?.comment_count
    }
  })

  return schema ? (
    <Script type='application/ld+json' id='podcast-schema'>
      {schema}
    </Script>
  ) : null
}

export function formatBrandStructuredDataMarkup(brand: Brand) {
  const { name, about, slug } = brand
  const brandPageUrl = `${process.env.SITE_URL}/shows/${brand.id}/${slug}`

  const schema = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    name: name,
    keywords: brand.tags,
    publisher: SEO_PUBLISHER_DEFAULT,
    mainEntity: {
      '@type': 'Organization',
      name: name,
      url: brandPageUrl,
      brand: {
        '@type': 'Brand',
        name: name,
        url: brandPageUrl,
        description: about,
        logo: {
          '@type': 'ImageObject',
          url: brand.logo
        }
      }
    }
  })
  return schema ? (
    <Script type='application/ld+json' id='brand-schema'>
      {schema}
    </Script>
  ) : null
}

export function formatAuthorStructuredDataMarkup(author: Author) {
  const { name, bio, slug, headshot, author_url } = author

  const schema = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    name: name,
    image: headshot,
    description: bio,
    identifier: slug,
    publisher: SEO_PUBLISHER_DEFAULT,
    mainEntity: {
      '@type': 'Person',
      name: name,
      image: headshot,
      description: bio,
      url: author_url
    }
  })
  return schema ? (
    <Script type='application/ld+json' id='author-schema'>
      {schema}
    </Script>
  ) : null
}

export function formatHomepageStructuredDataMarkup({
  description,
  defaultShareImage,
  url
}: {
  description: string
  defaultShareImage: string
  url: string
}) {
  const schema = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    headline: 'Barstool Sports',
    about: description,
    description: description,
    thumbnailUrl: defaultShareImage,
    url: url,
    image: defaultShareImage,
    mainEntityOfPage: url,
    publisher: SEO_PUBLISHER_DEFAULT,
    author: {
      '@type': 'Organization',
      name: 'Barstool Sports',
      url: 'https://www.barstoolsports.com/',
      logo: {
        '@type': 'ImageObject',
        url: defaultShareImage
      }
    }
  })
  return schema ? (
    <Script type='application/ld+json' id='homepage-schema'>
      {schema}
    </Script>
  ) : null
}

const SEO_PUBLISHER_DEFAULT = {
  '@type': 'Organization',
  name: 'Barstool Sports',
  legalName: 'Barstool Sports, Inc.',
  url: 'https://www.barstoolsports.com',
  description: brandDescription,
  founder: 'Dave Portnoy',
  logo: {
    '@type': 'ImageObject',
    url: 'https://chumley.barstoolsports.com/union/2021/02/02/logo.png'
  }
}
